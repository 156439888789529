import './CartaDerecha.css';
export default function CartaDerecha(props) {

	return(
		<>
			<div className="contPrinD" >	
			<div className='backGround' style={{
					backgroundImage: `url("${props.imagen1}")`,
					backgroundSize: 'cover',
					backgroundPosition: 'center',
					width: '100%',
					height:'100%',
					filter: 'blur(3px)',
				}}></div>
				<div className="contPrinD_carta">

					<div className="contPrinD_carta_text">
						<div className="contPrinD_carta_text_topTittle1">
							{props.titleText}
						</div>
						<div className='margenSuperior'></div>
						<div className="contPrinD_carta_text_P2">
							{props.text}
						</div>
					</div>	
				</div>
	
				<div className="contPrinD_cartaImg2">
					<div className="contPrinD_cartaImg2_imagen2">
						{<img src={props.imagen1} alt='fotos2' className='imgPortada'/>}
					</div>
				</div>
			</div>
			
    	</>
	)
}