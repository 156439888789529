import './Contacto.css';
export default function Contacto(props) {

	return(
		<>
      <div className='ContTotal'>
        <div className="ContTotal_izq">
          <div className='ContTotal_izq_text'>
            <div className="ContTotal_izq_text_title">
              <p>Contactos</p>
            </div>
            <p>Horario de atención: 8 a 17hs. Para emergencias industriales 24Hs.</p>
            <div className="pdatos"><img src={require('../fotos/whatIcon.png')}alt=''/><p>Ing. Edgar Danelutti +54 9 341 5850092</p></div>
            <div className="pdatos"><img src={require('../fotos/whatIcon.png')}alt=''/><p>Ing. Franco Danelutti: +54 9 341 3677491</p></div>
            <div className="pdatos"><img src={require('../fotos/whatIcon.png')}alt=''/><p>Ing. Giuliano Danelutti +54 9 341 6910205</p></div>
            <div className="pdatos"><img src={require('../fotos/mailIconBlanco.png')}/><p>daneluttiingenieria@gmail.com</p></div>
          </div>
        </div>
       
        <div className="ContTotal_der">
          {<div className="gmap_canvas"><iframe width="100%" height="100%" id="gmap_canvas" src="https://maps.google.com/maps?q=alvarez%20thomas%20990%20rosario&t=&z=13&ie=UTF8&iwloc=&output=embed" frameBorder="0" scrolling="no"></iframe></div>}
        </div>
      </div>
    </>
  )
}