import './Foot.css';
export default function Foot() {
	return(
		<>
			<div className="pieDeDatos">
				<div className="datos1">
					
					<img src={require('../fotos/imagenDIiconoLetras2.png')}alt=''/>

				</div>
				<div className="datos2">

				<div className="pdatos"><img src={require('../fotos/mailicon.png')}alt=''/>
				<p> daneluttiingenieria@gmail.com</p></div>
					
					<div className="pdatos"><img src={require('../fotos/whatappIconBlanco.png')}alt=''/><p>Ing. Edgar Danelutti +54 9 341 5850092</p></div>
					<div className="pdatos pdNuevo"><img src={require('../fotos/whatappIconBlanco.png')}alt=''/><p>Ing. Giuliano Danelutti +54 9 341 6910205</p></div>
					
				</div>
				<div className="datos3">
					<div className="pdatos pdNuevo"><img src={require('../fotos/whatappIconBlanco.png')}alt=''/><p>Ing. Franco Danelutti +54 9 341 3677491</p></div>
					<div className="pdatos"><img src={require('../fotos/ubiIcon.png')}alt=''/>
						<p>Alvarez Thomas, 990 Rosario, Santa Fe, Argentina</p>
					</div>
			

				</div>
			</div>


			<div className="containerFoot">
				<div className="finishFoot1">
					<p>© 2023 Danelutti Ingenieria</p>   
				</div>
				<div className="finishFoot2">
					<a href="#">Back To Top</a>   
				</div>
				<div className="finishFoot3">
					<p>Daneluttiingenieria@gmail.com</p>   
				</div>
			</div>
		</>
	)
}  