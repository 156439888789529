import './Carousel.css';

function Carousel(props){
  return(
    
		<div className='container-slider'>
			<div className='slider'>
        <div className='titulo1'>
          {props.textSlogan}
          <div className='textoCarousel1'>
          {/*props.textSlogan*/}
        </div>
        </div>
        
        <img src={require('../fotos/Portada1.jpg')} alt='fotos' className='img-slider'/>
        <img src={require('../fotos/ET.jpg')} alt='fotos2' className='img-slider2'/>
        <img src={require('../fotos/Paneles.jpg')} alt='fotos3' className='img-slider3'/>

      </div>
		</div>

    
  )
}
export default Carousel;