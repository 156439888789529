import './App.css';
import Menu from './Components/Menu';
import Carousel from './Components/Carousel';
import Quienes from './Components/Quienes2';
import CartaDerecha from './Components/CartaDerecha';
import CartaIzquierda from './Components/CartaIzquierda';
import Contacto from './Components/Contacto';

import Foot from './Components/Foot';
import React, { useEffect } from 'react';
import ReactGA from "react-ga4";

ReactGA.initialize("G-ZX8TGZTHWV");

const medicionesElectricas = (
  <>
    <p>Ensayos de Tension Aplicada: equipos hasta 80 kVcc y 56kVca.</p>
    
    <p>VLF, Tangente Delta y MWT.</p>
   
    <p>Miliohmímetro y Microhmímetro. Resistencia de contacto, Resistencia de bobinados en motores, generadores y transformadores.</p>
      
    <p>Ensayo de guantes, alfombras, taburetes, detectores, pértigas de media tensión.</p>
     
    <p>Reflectometría en cables de BT y MT.</p>

    <p>Seguimiento de cables enterrados con y sin tensión.</p>
 
    {/*<p>• Kilovoltímetro. Cordinación de fases, detectar fusibles quemados, etc.</p>
    <p>&nbsp;</p>*/}
    <p>Medición de cargas estáticas.</p>
  
    <p>Medición de potenciales en tanques y cañerías enterradas</p>
 
  </>
);
const serviciosIngenieria = (
  <>
    <p>Cálculo de cortocircuito y ArcFlash.</p>
   
    <p>Calculo y mejora de protección contra descargas atmosféricas.</p>

    <p>Calculo y medición de potenciales de cañerías enterradas.</p>

    <p>Tracing eléctricos, cálculo y diseño, instalación y mantenimiento.</p>

    <p>Verificación de tableros eléctricos.</p>

    <p>Cumplimiento de Resolución 900/15.</p>

    <p>Mejoras Y adecuación en sistema de puesta a tierra</p>
  </>
);
const serviciosGenerales = (
  <>
    <p>Ejecución de empalmes de media tensión.</p>

    <p>Localización de fallas en baja y media tensión.</p>

    <p>Seguimiento de trazas de cables, cañerías enterradas, etc.</p>

    {/*<p>• Localización de metales ferrosos enterrados.</p>
    <p>&nbsp;</p>*/}
    <p>Ejecución de mallas de puesta a tierra por soldaduras o por compresión.</p>
    <p>Compresión hexagonal de uniones y terminales con 20 toneladas o tornillos fusibles.</p>

    <p>Inspecciones ultrasónicas en: terminales – empalmes – celdas – motores –TK – cañerías – generadores – ductos - etc.</p>

    <p>Inspecciones termográficas.</p>

  </>
);
const ProductosGenerales = (
  <>
    <p>Empalmes unipolares y tripolares de 13,2 kV y 33 kV.</p>

    <p>Terminales unipolares y tripolares para interior y exterior de 13,2kV y 33kV.</p>

    <p>Equipos de medición.</p>

    <p>Sensores.</p>

    <p>Etiquetas térmicas.</p>

    <p>Cintas calefactoras (tracing eléctrico).</p>

    <p>Capuchones aislantes para borne de transformadores, aislación de barras de baja tensión y media tensión.</p>

    <p>Protección atmosférica.</p>

    <p>Protección de sobre tensiones Marca DEHN.</p>

    <p>Protección catódica.</p>

    <p>Tubos y mantas termo contraíble con y sin adhesivo de todos los diámetros y espesores para usos múltiples (aislación de barras). Capuchones, Bifurcaciones, etc.</p>

    <p>Campanas aislantes.</p>    
  </>
);
const ElementosSeguridad = (
  <>
    <p>Cascos de seguridad. </p>

    <p>Botines de seguridad.</p>

    <p>Guantes anticortes.</p>

    <p>Guantes dieléctricos.</p>

    <p>Alfombras dieléctricas.</p>

    <p>Pértigas.</p>

    <p>Mantas cortafuegos.</p>

    <p>Ropa institucional.</p>

    <p>Candados de Seguridad.</p>
  </>
);
const RopaArcFlash = (
  <>
    <p>Camisa.</p>

    <p>Pantalón.</p>

    <p>Overol.</p>

    <p>Guardapolvo.</p>

    <p>Guantes Dehn.</p>

    <p>Protectores Faciales.</p>

    <p>*Estos elementos son para arc Flash desde clase II (8 cal/cm2), hasta clase IV (40 cal/cm2).</p>
  </>
);
const QuienesText = (
  <>
    <p>Somos una empresa familiar con larga trayectoria especializada en ingeniería eléctrica, fundada formalmente en 1994 y situada en Rosario, Santa Fe. Dedicada a brindar soluciones a la industria de la zona, como también realizando trabajos en otras provincias, mediante servicios de ingeniería, venta de materiales y asesoramiento técnico.</p>
    <p>Nuestra prioridad es poder cumplir con las necesidades de nuestros clientes en tiempo y forma, para ello nos fuimos adaptando a través del cambio de las distintas legislaciones y normas de seguridad de cada empresa, para dar un servicio que busca siempre la excelencia.</p>
    <p>La empresa está constituida por un plantel de ingenieros matriculados, altamente capacitado y con larga trayectoria en la industria.</p>
    <p>Nos motiva ayudar a la industria nacional, buscar nuevos desafíos y seguir perfeccionándonos profesionalmente.</p>
  </>
);
const QuienesTextMovil=(
  <>
  <p>Somos una empresa de ingeniería eléctrica en Rosario, Santa Fe. Ofrecemos soluciones a la industria local con un equipo de ingenieros altamente capacitados y adaptados a las normas y regulaciones de seguridad. Nuestra prioridad es ayudar a la industria nacional a prosperar y seguir mejorando profesionalmente. Estamos comprometidos con la calidad y ofrecemos soluciones innovadoras y personalizadas a nuestros clientes.</p>
  </>
);


function App() {
  
  return (
    
    <div className="App">
      <Menu/>
      <Carousel textSlogan={"Experiencia y excelencia que nos distinguen"} />
      <div className='separador' id='quienes'></div>
      <Quienes text={QuienesText} textMovil={QuienesTextMovil}/>
      <div className='separador' id='servicios'></div>
      <CartaDerecha titleText={"Mediciones Eléctricas"} text={medicionesElectricas} imagen1={require('./fotos/motores1.jpg')}/>
      <CartaIzquierda titleText={"Servicios de Ingeniería"} text={serviciosIngenieria} imagen1={require('./fotos/grua.jpg')}/>
      <CartaDerecha titleText={"Servicios Generales"} text={serviciosGenerales} imagen1={require('./fotos/tanques.jpg')}/>
      <div className='separador' id='productos'></div>
      <CartaIzquierda titleText={"Productos Generales"} text={ProductosGenerales} imagen1={require('./fotos/productos2.jpg')}/>
      <CartaDerecha titleText={"Elementos de Seguridad"} text={ElementosSeguridad} imagen1={require('./fotos/ETNosotros.jpg')}/>
      <CartaIzquierda titleText={"Ropa de ArcFlash"} text={RopaArcFlash} imagen1={require('./fotos/imagen2.jpg')}/>
      <div className='separador' id='contactos'></div>
      <Contacto/>
      <Foot/>

    </div>
  );
}

export default App;
