import React,{useRef} from 'react'
import './Menu.css';

function Menu() {
	let menuToggle =useRef(null);
	let mainMenu=useRef(null);
	
	const handleToggle=(e)=>{
		
		if(mainMenu.current.className=="mainMenu"){
			
				mainMenu.current.className="mainMenu_Hidden";
		}else if(mainMenu.current.className=="mainMenu_Hidden"){
			mainMenu.current.className="mainMenu";
			
		}

	}
  return ( 
    <>
		<div className="separadorStickyTop"/>
    	<div className="mainNav">
			<div className='ContenedorNavTop'>
				<div className="Container_Icon" id="icon-menu">
					<img src={require('../fotos/imagenDIiconoLetras2.png')}alt=''/>
				</div>
				<div className="Container_Toggle" id="toggle-menu" ref={menuToggle}>
					<img src={require('../fotos/famenu2.png')}alt='' onClick={handleToggle}/>
				</div>
				<div className="desktopMenu">
					<ul>
						<li className='desktopMenu__item'>
							<a href='#' className='mainMenu__link'>Inicio</a>
						</li>
						<li className='desktopMenu__item'>
							<a href='#quienes' className='mainMenu__link'>Quienes Somos</a>
						</li>
						<li className='desktopMenu__item'>
							<a href='#servicios' className='mainMenu__link'>Servicios</a>
						</li>
						<li className='desktopMenu__item'>
							<a href='#productos' className='mainMenu__link'>Productos</a>
						</li>
						<li className='desktopMenu__item'>
							<a href='#contactos' className='mainMenu__link'>Contacto</a>
						</li>
					</ul>

				</div>
			</div>
				
			<div className='mainMenu_Hidden' ref={mainMenu}>
				<ul>
					<li className='mainMenu__item'>
						<a href='#' className='mainMenu__link'onClick={handleToggle}>INICIO</a>
					</li>
					<li className='mainMenu__item'>
						<a href='#quienes' className='mainMenu__link' onClick={handleToggle}>QUIENES SOMOS</a>
					</li>
					<li className='mainMenu__item'>
						<a href='#servicios' className='mainMenu__link'onClick={handleToggle}>SERVICIOS</a>
					</li>
					<li className='mainMenu__item'>
						<a href='#productos' className='mainMenu__link'onClick={handleToggle}>PRODUCTOS</a>
					</li>
					<li className='mainMenu__item'>
						<a href='#contactos' className='mainMenu__link'onClick={handleToggle}>CONTACTO</a>
					</li>
				</ul>
			</div>
    	</div>
    </>
  )	
}

export default Menu