import './Quienes2.css';
export default function Quienes2(props) {
		
	return(
		<>
			<div className="contenedorPrincipal">
				<div className="carta1">

					<div className="cartaTexto1">

						<div className="texto1">
							<div className="topTittle">
								<p>Quienes Somos</p>
							</div>
							<div className="textP">
								{props.text}
							</div>
						</div>	
						<div className="textoMovil">
							<div className="topTittle">
								<p>Quienes Somos</p>
							</div>
							<div className="textP">
								{props.textMovil}
							</div>
						</div>	
					</div>
				</div>

				<div className="cartaImagen1">
					<div className="imagen1">
						{<img src={require('../fotos/ET2.jpg')} alt='fotos2' className='imgPortada'/>}
					</div>
				</div>
			</div>
		</>
	)
}