import './CartaIzquierda.css';
export default function CartaIzquierda(props) {
		
	return(
		<>
			<div className="contPrinI">
			<div className='backGround' style={{
					backgroundImage: `url("${props.imagen1}")`,
					backgroundSize: 'cover',
					backgroundPosition: 'center',
					width: '100%',
					height:'100%',
					filter: 'blur(3px)',
				}}></div>
				<div className="contPrinI_carta">

					<div className="contPrinI_carta_text">
						<div className="contPrinD_carta_text_topTittle1">
							{props.titleText}
						</div>
						<div className='margenSuperior'></div>
						<div className="contPrinD_carta_text_P2">
							{props.text}
						</div>
					</div>	
				</div>

				<div className="contPrinI_cartaImg2">
					<div className="contPrinI_cartaImg2_imagen2">
						{<img src={props.imagen1} alt='fotos2' className='imgPortada'/>}
					</div>
				</div>
			</div>
    	</>
	)
}